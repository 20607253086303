<template>
  <div class="">
    <div class="flex">
      <div class="container mx-auto">
        <div class="flex">
          <form v-if="!isLoading" class="needs-validation" @submit.prevent="onSubmit">
            <div class="mx-4">
              <div class="mb-4 text-xl font-bold">Edit: {{ agent.entity_name }}</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="entity_name"
                  >
                    Entity name
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="entity_name"
                    id="entity_name"
                    v-model="form.entity_name"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('email'),
                    }"
                    required
                    placeholder="change entity name"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="contact_person"
                  >
                    Contact person
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="contact_person"
                    id="contact_person"
                    v-model="form.contact_person"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('contact_person'),
                    }"
                    required
                    placeholder="change contact person"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="contact_person_number"
                  >
                    Contact person Number
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="contact_person_number"
                    v-model="form.contact_person_number"
                    type="tel"
                    :class="{
                      'is-invalid': form.errors.has('contact_person_number'),
                    }"
                    required
                    placeholder="change contact person number"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Business Address
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="business_address"
                    v-model="form.business_address"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('business_address'),
                    }"
                    required
                    placeholder="Change Business Address"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <button
                  class="px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Swal from "sweetalert2";

export default {
  middleware: "auth",

  layout: "default",
  data: () => ({
    isLoading: false,

    branches: [],
    roles: [],
    form: new Form({
      contact_person: "",
      entity_name: "",
      contact_person_number: "",
      business_address: "",
      agent_id: null,
    }),
  }),

  created() {
    this.loadAgent(this.$route.params.id);
  },

  methods: {
    async loadAgent(id) {
      this.isLoading = true;

      try {
        const { data } = await axios.get(`/agent/${id}`);

        this.isLoading = false;
        this.agent = data.data;
        this.form.agent_id = data.data.id;
        this.form.entity_name = this.agent.entity_name;
        this.form.contact_person = this.agent.contact_person;
        this.form.contact_person_number = this.agent.contact_person_number;
        this.form.business_address = this.agent.business_address;
        return true;
      } catch (e) {
        console.error("Error", e);
        this.isLoading = false;
        return false;
      }
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        const { data } = await this.form.patch(`/agent/${this.form.agent_id}/edit`);
        if (data) {
          Swal.fire({
            icon: "success",
            title: "Update",
            text: "Agent successfully updated",
          });
          this.isLoading = false;
          this.$router.push({ path: "/agents" });
        }

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>
