var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"flex"},[(!_vm.isLoading)?_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mx-4"},[_c('div',{staticClass:"mb-4 text-xl font-bold"},[_vm._v("Edit: "+_vm._s(_vm.agent.entity_name))])]),_c('div',{staticClass:"flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"entity_name"}},[_vm._v(" Entity name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.entity_name),expression:"form.entity_name"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",class:{
                    'is-invalid': _vm.form.errors.has('email'),
                  },attrs:{"name":"entity_name","id":"entity_name","type":"text","required":"","placeholder":"change entity name"},domProps:{"value":(_vm.form.entity_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "entity_name", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"contact_person"}},[_vm._v(" Contact person ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact_person),expression:"form.contact_person"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",class:{
                    'is-invalid': _vm.form.errors.has('contact_person'),
                  },attrs:{"name":"contact_person","id":"contact_person","type":"text","required":"","placeholder":"change contact person"},domProps:{"value":(_vm.form.contact_person)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact_person", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"contact_person_number"}},[_vm._v(" Contact person Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact_person_number),expression:"form.contact_person_number"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",class:{
                    'is-invalid': _vm.form.errors.has('contact_person_number'),
                  },attrs:{"name":"contact_person_number","type":"tel","required":"","placeholder":"change contact person number"},domProps:{"value":(_vm.form.contact_person_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact_person_number", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Business Address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.business_address),expression:"form.business_address"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",class:{
                    'is-invalid': _vm.form.errors.has('business_address'),
                  },attrs:{"name":"business_address","type":"text","required":"","placeholder":"Change Business Address"},domProps:{"value":(_vm.form.business_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "business_address", $event.target.value)}}})])]),_vm._m(0)])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('button',{staticClass:"px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700",attrs:{"type":"submit"}},[_vm._v(" Update ")])])}]

export { render, staticRenderFns }